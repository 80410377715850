<div
  class="app-shell-wrapper"
  [ngStyle]="{ 'flex-direction': isMobileScreen ? 'column' : 'row' }"
  data-cy="app-shell-wrapper"
>
  <!-- Mobile Navigation Bar -->
  <div *ngIf="isMobileScreen" class="mobile-dropdown">
    <div class="mobile-header">
      <img
        [src]="
          subdomainType === SubdomainTypes.EagleProcessing
            ? '../../assets/eagle-logo.png'
            : '../../assets/enroll-and-pay-logo.png'
        "
        alt="image"
        class="logo-image"
        class="logo-image"
      />

      <fa-icon
        [icon]="icons['faBars']"
        class="header-icon"
        (click)="onToggleMobileDropdown()"
        data-cy="mobile-nav-toggle"
      ></fa-icon>
    </div>

    <div class="dropdown-content" *ngIf="showMobileDropdown">
      <a
        *ngIf="shouldShowMenuItem(AccessType.SaleOverview)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigation('dashboard')"
        routerLinkActive="active"
      >
        <div class="nav-link-content">
          <fa-icon [icon]="icons['faHouse']" class="mr-3"></fa-icon>
          <span class="nav-label">Dashboard</span>
        </div>
      </a>
      <a
        *ngIf="shouldShowMenuItem(AccessType.OfferAdmin)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigation('offers')"
        routerLinkActive="active"
        data-cy="nav-offers"
      >
        <div class="nav-link-content">
          <fa-icon [icon]="icons['faMoneyBill']" class="mr-3"></fa-icon>
          <span class="nav-label">Offers</span>
        </div>
      </a>
      <a
        *ngIf="shouldShowMenuItem(AccessType.ConsumerAccess)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigation('members')"
        routerLinkActive="active"
      >
        <div class="nav-link-content">
          <fa-icon [icon]="icons['faUsers']" class="mr-3"></fa-icon>
          <span class="nav-label">Members</span>
        </div>
      </a>
      <a
        *ngIf="shouldShowMenuItem(AccessType.OrderAdmin)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigation('transactions')"
        routerLinkActive="active"
      >
        <div class="nav-link-content">
          <fa-icon [icon]="icons['faCreditCard']" class="mr-3"></fa-icon>
          <span class="nav-label">Transactions</span>
        </div>
      </a>
      <a
        *ngIf="shouldShowMenuItem(AccessType.OrderAdmin)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigation('reviews')"
        routerLinkActive="active"
      >
        <div class="nav-link-content">
          <fa-icon [icon]="icons['faStar']" class="mr-3"></fa-icon>
          <span class="nav-label">Reviews</span>
        </div>
      </a>
      <a
        *ngIf="shouldShowMenuItem(AccessType.SendReceiveMessages)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigationIFrame('messages')"
        routerLinkActive="active"
      >
        <div class="nav-link-content">
          <fa-icon [icon]="icons['faComment']" class="mr-3"></fa-icon>
          <span class="nav-label">Messages</span>
        </div>
      </a>
      <a
        *ngIf="shouldShowMenuItem(AccessType.ReportAdmin)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigationIFrame('reports')"
        routerLinkActive="active"
      >
        <div class="nav-link-content">
          <fa-icon [icon]="icons['faFileExport']" class="mr-3"></fa-icon>
          <span class="nav-label">Reports</span>
        </div>
      </a>
      <a
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigation('admin')"
        routerLinkActive="active"
        data-cy="nav-admin-mobile"
      >
        <div class="nav-link-content">
          <fa-icon [icon]="icons['faGear']" class="mr-3"></fa-icon>
          <span class="nav-label">Admin</span>
        </div>
      </a>
    </div>
  </div>

  <!-- Tablet/Desktop Navigation Bar -->
  <div
    *ngIf="!isMobileScreen"
    [ngClass]="{
      'minimized-sidebar': (isSmallScreen && !shouldShowFullWidth) || !shouldShowFullWidth
    }"
  >
    <div class="side-navigation-bar">
      <div class="side-navigation-bar-header">
        <img [src]="getImageSrc()" alt="image" class="logo-image" />
      </div>
      <!-- Your side navigation bar content here -->
      <nav class="nav">
        <a
          *ngIf="shouldShowMenuItem(AccessType.SaleOverview)"
          class="nav-link"
          (click)="onNavigation('dashboard')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Dashboard' : ''"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faHouse']" size="lg" class="mr-3"></fa-icon>
            <span class="nav-label">Dashboard</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.OfferAdmin)"
          class="nav-link"
          (click)="onNavigation('offers')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Offers' : ''"
          data-cy="nav-offers-mobile"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faMoneyBill']" size="lg" class="mr-3"></fa-icon>
            <span class="nav-label">Offers</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.ConsumerAccess)"
          class="nav-link"
          (click)="onNavigation('members')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Members' : ''"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faUsers']" size="lg" class="mr-3"></fa-icon>
            <span class="nav-label">Members</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.OrderAdmin)"
          class="nav-link"
          (click)="onNavigation('transactions')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Transactions' : ''"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faCreditCard']" size="lg" class="mr-3"></fa-icon>
            <span class="nav-label">Transactions</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.OrderAdmin)"
          class="nav-link"
          (click)="onNavigation('reviews')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Reviews' : ''"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faStar']" size="lg" class="mr-3"></fa-icon>
            <span class="nav-label">Reviews</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.SendReceiveMessages)"
          class="nav-link"
          (click)="onNavigationIFrame('messages')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Messages' : ''"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faComment']" size="lg" class="mr-3"></fa-icon>
            <span class="nav-label">Messages</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.ReportAdmin)"
          class="nav-link"
          (click)="onNavigationIFrame('reports')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Reports' : ''"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faFileExport']" size="lg" class="mr-3"></fa-icon>
            <span class="nav-label">Reports</span>
          </div>
        </a>
        <a
          class="nav-link"
          (click)="onNavigation('admin')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Admin' : ''"
          data-cy="nav-admin"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faGear']" size="lg" class="mr-3"></fa-icon>
            <span class="nav-label">Admin</span>
          </div>
        </a>
      </nav>
    </div>
  </div>

  <!-- Main Content -->
  <div class="main-content">
    <app-header (closeClicked)="onCloseClicked()"></app-header>
    <div class="app-content">
      <!-- Your main content, including header and router-outlet -->
      <p-breadcrumb *ngIf="shouldShowBreadcrumbs" [model]="(breadcrumbs$ | async) || []">
        <ng-template pTemplate="item" let-item>
          <ng-container>
            <div (click)="item.command()" class="breadcrumb-item">
              <fa-icon *ngIf="item.icon" [icon]="icons[item.icon]" size="xl"></fa-icon>
              <span>{{ item.label }}</span>
            </div>
          </ng-container>
        </ng-template>
      </p-breadcrumb>
      <ep-iframe-manager
        #iframeManager
        id="iframe-manager"
        [iframeWidth]="iframeWidth"
        [iframeHeight]="iframeHeight"
        [ngClass]="{ hidden: isLoading || !shouldShowIframe }"
        data-cy="iframe-manager"
      ></ep-iframe-manager>
      <div *ngIf="!isLoading && !shouldShowIframe" id="merchant-portal-routing-wrapper">
        <router-outlet></router-outlet>
      </div>
      <app-loading-spinner
        *ngIf="isLoading || (shouldShowIframe && !isLegacyLoaded)"
        [message]="'Loading...'"
        class="text-center"
      ></app-loading-spinner>
    </div>
  </div>
</div>
