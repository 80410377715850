import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeState, InvoiceState } from '@app/resources/ngrx/states';
import { isNotNullOrUndefined } from '@ep/shared';
import { selectInvoiceEntities } from '@app/resources/ngrx/adapters';
import { selectOrderState } from '@app/resources/ngrx/selectors/order.selector';

export const selectInvoiceState = createFeatureSelector<InvoiceState>('invoice');
export const getInvoiceEntities = createSelector(selectInvoiceState, selectInvoiceEntities);

export const hasPastInvoicesLoaded = createSelector(selectInvoiceState, (state) => state.hasLoaded);
export const selectPastInvoices = createSelector(
  getInvoiceEntities,
  hasPastInvoicesLoaded,
  (invoiceEntities, hasLoaded) => {
    if (!hasLoaded) return null;
    return Object.values(invoiceEntities)
      .filter(isNotNullOrUndefined)
      .sort((a, b) => b.InvoiceId - a.InvoiceId);
  }
);

export const selectPastInvoiceId = createSelector(selectInvoiceState, (state) => {
  return state.selectedPastInvoiceId;
});

export const selectPastInvoice = createSelector(
  getInvoiceEntities,
  selectPastInvoiceId,
  (invoiceEntities, invoiceId) => {
    console.log('selectPastInvoice ', invoiceEntities, invoiceId);
    return invoiceEntities[invoiceId ?? 0];
  }
);
