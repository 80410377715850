import { inject, Injectable } from '@angular/core';
import { Order, OrderService } from '@app/resources/services';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapToOrders } from '@app/areas/transactions/utils';

@Injectable()
export class TransactionApiService {
  private readonly orderService = inject(OrderService);

  getTransactions(): Observable<Order[]> {
    return this.orderService.findAll().pipe(map(mapToOrders));
  }

  getTransaction(orderId: number): Observable<boolean> {
    return this.orderService.findUnique(orderId).pipe(
      map((response) => !!response),
      catchError((err) => {
        console.error(err);
        return of(false);
      })
    );
  }
}
