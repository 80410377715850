import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { Observable } from 'rxjs';
import { InvoiceItemTypes, InvoiceStatusTypes } from '@shared/enums';

export interface InvoiceItem {
  InvoiceItemId: number;
  InvoiceId: number;
  Name: string;
  Quantity: number;
  BilledAmount: number;
  ItemType: InvoiceItemTypes;
}

export interface Invoice {
  InvoiceId: number;
  Name: string;
  SmsCost: number;
  AmountDue: number;
  StatusType: InvoiceStatusTypes;
  Items: InvoiceItem[];
}

export interface InvoiceItemResponse {
  invoiceItemId: number;
  invoiceId: number;
  name: string;
  quantity: number;
  billedAmount: number;
  itemType: InvoiceItemTypes;
}

export interface InvoiceResponse {
  invoiceId: number;
  name: string;
  smsCost: number;
  amountDue: number;
  statusType: InvoiceStatusTypes;
  items: {
    $values: InvoiceItemResponse[];
  };
}

interface InvoicesResponse {
  $values: InvoiceResponse[];
}

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends BaseApiService {
  private readonly invoiceEndpoint = '/v2/Invoices';

  findCurrent(): Observable<InvoiceResponse> {
    return this.get(`${this.invoiceEndpoint}/current`);
  }

  findAllPast(): Observable<InvoicesResponse> {
    return this.get(`${this.invoiceEndpoint}/past`);
  }

  findById(invoiceId: number): Observable<InvoiceResponse> {
    return this.get(`${this.invoiceEndpoint}/${invoiceId}`);
  }
}
