import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ReviewsComponent } from './reviews.component';
import { AccessGuard } from '@app/@shared/guards/access.guard';
import { AccessType } from '@app/@shared/enums/access-types.enum';
import { ExistingOrderReviewComponent } from '@app/areas/reviews/components/existing-order-review/existing-order-review.component';
import { ReviewModuleRouteResolver, ReviewRouteResolver } from '@app/areas/reviews/resolvers';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':locationId/reviews',
      component: ReviewsComponent,
      canActivate: [AccessGuard],
      data: { title: 'Reviews', requiredAccessTypes: [AccessType.OrderAdmin] },
      resolve: { message: ReviewModuleRouteResolver },
    },
    {
      path: ':locationId/reviews/:orderReviewId',
      component: ExistingOrderReviewComponent,
      canActivate: [AccessGuard],
      data: { title: 'Reviews', requiredAccessTypes: [AccessType.OrderAdmin] },
      resolve: { data: ReviewModuleRouteResolver, message: ReviewRouteResolver },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReviewsRoutingModule {}
