import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';

export interface OrderReviewResponse {
  orderReviewId: string;
  consumer: string;
  score?: number | null;
  createdDateTime: Date | string;
  updateDateTime: Date | string;
}

export interface OrderReviewsResponse {
  $values: OrderReviewResponse[];
}

export interface OrderReview {
  OrderReviewId: string;
  Consumer: string;
  Score?: number | null;
  CreatedDateTime: Date | string;
  UpdateDateTime: Date | string;
}

export interface LegacyOrderReview {
  OrderReviewId: string;
  ConsumerId: number;
  OrderId: number;
}

export interface LegacyOrderReviewResponse {
  OrderReview: LegacyOrderReview;
}

@Injectable({
  providedIn: 'root',
})
export class OrderReviewService extends BaseApiService {
  private readonly orderReviewEndpoint = '/v2/OrderReviews';
  private readonly orderReviewLegacyEndpoint = '/OrderReview';

  findAll() {
    return this.get<OrderReviewsResponse>(`${this.orderReviewEndpoint}`).pipe(
      catchError((err) => {
        console.error(err);
        const emptyOrderReviewsResponse: OrderReviewsResponse = { $values: [] };
        return of(emptyOrderReviewsResponse);
      })
    );
  }

  findUnique(orderReviewId: string) {
    return this.get<LegacyOrderReviewResponse>(`${this.orderReviewLegacyEndpoint}/${orderReviewId}`, {}, true);
  }
}
