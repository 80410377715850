import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EpTableComponent, EPTableConfig, LoadingSpinnerDirective } from '@ep/shared';
import { OrderReview } from '@app/resources/services';
import { Observable } from 'rxjs';
import { selectOrderReviews, selectOrderReviewsSortedByCreatedDate } from '@app/resources/ngrx/selectors';
import { getOrderReviewStatusSeverity, mapOrderReviewStatusToValue } from '@app/areas/reviews/utils/mappers';
import { OrderReviewModuleActions } from '@app/areas/reviews/store';
import { IframeManagerService } from '@shared/services/iframe-manager.service';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-order-reviews-table',
  standalone: true,
  imports: [EpTableComponent, AsyncPipe, LoadingSpinnerDirective],
  templateUrl: './order-reviews-table.component.html',
  styleUrl: './order-reviews-table.component.scss',
})
export class OrderReviewsTableComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly iframeManagerService = inject(IframeManagerService);

  protected config: EPTableConfig<OrderReview> = {
    header: 'reviews',
    pagination: {
      rows: 10,
      showCurrentPageReport: true,
    },
    columns: [
      {
        header: 'Create Date',
        field: 'CreatedDateTime',
        columnType: 'date',
        dateFormat: 'MM/dd/YYYY',
        defaultValue: 'Missing date',
      },
      {
        header: 'Name',
        field: 'Consumer',
        columnType: 'string',
        defaultValue: 'VALUED CUSTOMER',
      },
      {
        header: 'Score',
        field: 'Score',
        columnType: 'categorical',
        defaultValue: 'Poor',
        fieldToValueMap: mapOrderReviewStatusToValue,
        severity: getOrderReviewStatusSeverity,
      },
      {
        header: 'Last Updated',
        field: 'UpdateDateTime',
        columnType: 'date',
        dateFormat: 'MM/dd/YYYY',
        defaultValue: 'Missing date',
      },
    ],
    style: {},
    globalFilterFields: ['Name'],
    actions: {
      rowClick: (orderReview: OrderReview) => {
        this.store.dispatch(
          OrderReviewModuleActions.navigateExistingOrderReview({
            payload: { orderReviewId: orderReview.OrderReviewId },
          })
        );
      },
    },
  };

  protected orderReviews$: Observable<OrderReview[] | null> = this.store.select(selectOrderReviewsSortedByCreatedDate);

  ngOnInit() {
    this.iframeManagerService.setShouldShowIframe(false);
  }
}
