import { inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectOrderReviews } from '@app/resources/ngrx/selectors';
import { OrderReviewActions } from '@app/resources/ngrx/actions';

@Injectable()
export class ReviewModuleRouteResolver implements Resolve<any> {
  private readonly store = inject(Store);

  resolve(route: ActivatedRouteSnapshot) {
    return this.store.select(selectOrderReviews).pipe(
      take(1),
      tap((orderReviews) => {
        if (!orderReviews?.length) this.store.dispatch(OrderReviewActions.loadOrderReviews());
      })
    );
  }
}
