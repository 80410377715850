export enum OrderStatusTypes {
  Open = 1,
  Canceled = 2,
  Completed = 4,
  Voided = 8,
  OpenTab = 16, // 0x00000010
  PosOutOfSync = 32, // 0x00000020
  CancelPending = 64, // 0x00000040
  VoidPending = 128, // 0x00000080
}
