import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersComponent } from './members.component';
import { Shell } from '@app/shell/shell.service';
import { AccessGuard } from '@app/@shared/guards/access.guard';
import { AccessType } from '@app/@shared/enums/access-types.enum';
import { ExistingMemberComponent } from '@app/areas/members/components/existing-member/existing-member.component';
import { MemberModuleRouteResolver, MemberRouteResolver } from '@app/areas/members/resolvers';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':locationId/members',
      component: MembersComponent,
      canActivate: [AccessGuard],
      data: { title: 'Members', requiredAccessTypes: [AccessType.ConsumerAccess] },
      resolve: { message: MemberModuleRouteResolver },
    },
    {
      path: ':locationId/members/:consumerId',
      component: ExistingMemberComponent,
      canActivate: [AccessGuard],
      data: { title: 'Members', requiredAccessTypes: [AccessType.ConsumerAccess] },
      resolve: { data: MemberModuleRouteResolver, message: MemberRouteResolver },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MembersRoutingModule {}
