import { createActionGroup, props } from '@ngrx/store';

/*
 * OrderReview Module Actions
 */
enum OrderReviewModuleActionTypes {
  NAVIGATE_EXISTING_ORDER_REVIEW = 'Navigate Existing Order Review',
}

export const OrderReviewModuleActions = createActionGroup({
  source: 'OrderReview Module',
  events: {
    [OrderReviewModuleActionTypes.NAVIGATE_EXISTING_ORDER_REVIEW]: props<{ payload: { orderReviewId: string } }>(),
  },
});
