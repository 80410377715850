import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';

export interface OrderResponse {
  orderId: number;
  orderGuid: string;
  employee: string;
  statusType: number;
  enrollmentType: number;
  amountTotal: number;
  startDateTime: Date | string;
}

export interface OrdersResponse {
  $values: OrderResponse[];
}

export interface Order {
  OrderId: number;
  OrderGuid: string;
  Employee: string;
  StatusType: number;
  EnrollmentType: number;
  AmountTotal: number;
  StartDateTime: Date | string;
}

export interface LegacyOrder {
  OrderId: number;
}

export interface LegacyOrderResponse {
  Order: LegacyOrder;
}

@Injectable({
  providedIn: 'root',
})
export class OrderService extends BaseApiService {
  private readonly orderEndpoint = '/v2/Orders';
  private readonly orderLegacyEndpoint = '/Order';

  findAll() {
    return this.get<OrdersResponse>(`${this.orderEndpoint}`).pipe(
      catchError((err) => {
        console.error(err);
        const emptyOrdersResponse: OrdersResponse = { $values: [] };
        return of(emptyOrdersResponse);
      })
    );
  }

  findUnique(orderId: number) {
    return this.get<LegacyOrderResponse>(`${this.orderLegacyEndpoint}/${orderId}`, {}, true);
  }
}
