import { createReducer, on } from '@ngrx/store';
import { InvoiceState } from '@app/resources/ngrx/states';
import { InvoiceActions } from '@app/resources/ngrx/actions';
import { invoiceAdapter } from '@app/resources/ngrx/adapters';

export const initialInvoiceModuleState: InvoiceState = invoiceAdapter.getInitialState({
  selectedPastInvoiceId: 0,
  selectedId: 0,
  hasLoaded: true,
});

export const _invoiceReducer = createReducer(
  initialInvoiceModuleState,
  on(InvoiceActions.loadPastInvoicesSuccess, (state, { payload }) => {
    invoiceAdapter.removeAll(state);
    return {
      ...invoiceAdapter.addMany(payload.invoices, state),
      hasLoaded: true,
    };
  }),
  on(InvoiceActions.pastInvoiceById, (state, { payload }) => {
    console.log('pastInvoiceById');
    return Object.assign({
      ...state,
      selectedPastInvoiceId: payload.invoiceId,
    });
  })
);
