import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembersRoutingModule } from './members-routing.module';
import { MembersComponent } from './members.component';
import { EffectsModule } from '@ngrx/effects';
import { MemberModuleEffects } from '@app/areas/members/store';
import { MembersApiService } from '@app/areas/members/services';
import { MemberModuleRouteResolver, MemberRouteResolver } from '@app/areas/members/resolvers';

@NgModule({
  imports: [CommonModule, MembersRoutingModule, MembersComponent, EffectsModule.forFeature([MemberModuleEffects])],
  providers: [MembersApiService, MemberModuleRouteResolver, MemberRouteResolver],
})
export class MembersModule {}
