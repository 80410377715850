import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeState } from '@app/resources/ngrx/states';
import { isNotNullOrUndefined } from '@ep/shared';
import * as fromEmployeeAdapter from '@app/resources/ngrx/adapters';

export const selectEmployeeState = createFeatureSelector<EmployeeState>('employee');
export const selectEmployeeEntities = createSelector(selectEmployeeState, fromEmployeeAdapter.selectEmployeeEntities);

export const hasEmployeesLoaded = createSelector(selectEmployeeState, (state) => state.hasLoaded);
export const selectEmployees = createSelector(
  selectEmployeeEntities,
  hasEmployeesLoaded,
  (employeeEntities, hasLoaded) => {
    if (!hasLoaded) return null;
    return Object.values(employeeEntities).filter(isNotNullOrUndefined);
  }
);

export const getSelectedEmployeeId = (state: EmployeeState) => {
  return state.selectedEmployeeId;
};
export const selectCurrentEmployeeId = createSelector(selectEmployeeState, getSelectedEmployeeId);

export const selectCurrentEmployee = createSelector(
  selectEmployeeEntities,
  selectCurrentEmployeeId,
  (employeeEntities, employeeId) => {
    return employeeEntities[employeeId ?? 0];
  }
);
