import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceState } from '@app/resources/ngrx/states';
import { isNotNullOrUndefined } from '@ep/shared';
import * as fromDeviceAdapter from '@app/resources/ngrx/adapters';

export const selectDeviceState = createFeatureSelector<DeviceState>('device');
export const selectDeviceEntities = createSelector(selectDeviceState, fromDeviceAdapter.selectDeviceEntities);

export const hasDevicesLoaded = createSelector(selectDeviceState, (state) => state.hasLoaded);
export const selectDevices = createSelector(selectDeviceEntities, hasDevicesLoaded, (deviceEntities, hasLoaded) => {
  if (!hasLoaded) return null;
  return Object.values(deviceEntities).filter(isNotNullOrUndefined);
});

export const getSelectedDeviceId = (state: DeviceState) => {
  return state.selectedDeviceId;
};
export const selectCurrentDeviceId = createSelector(selectDeviceState, getSelectedDeviceId);

export const selectCurrentDevice = createSelector(
  selectDeviceEntities,
  selectCurrentDeviceId,
  (deviceEntities, deviceId) => {
    return deviceEntities[deviceId ?? 0];
  }
);

export const selectCurrentDeviceTrackings = createSelector(
  selectDeviceEntities,
  selectCurrentDeviceId,
  (deviceEntities, deviceId) => deviceEntities[deviceId ?? 0]?.DeviceTrackings ?? []
);
