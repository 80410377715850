import { Order, OrderResponse, OrdersResponse } from '@app/resources/services';
import { EnrollmentTypes, OrderStatusTypes } from '@shared/enums';
import { TransactionTableDisplay } from '@app/areas/transactions/models';

export const mapToOrders = (data: OrdersResponse): Order[] => {
  return data.$values.map(mapToOrder);
};

export const mapToOrder = (data: OrderResponse): Order => {
  return {
    OrderId: data.orderId,
    OrderGuid: data.orderGuid,
    Employee: data.employee,
    StatusType: data.statusType,
    AmountTotal: data.amountTotal,
    StartDateTime: data.startDateTime,
    EnrollmentType: data.enrollmentType,
  };
};

export const mapToTransactionTableDisplays = (data: Order[] | null): TransactionTableDisplay[] | null => {
  return !data ? null : data.map(mapToTransactionTableDisplay);
};

export const mapToTransactionTableDisplay = (data: Order): TransactionTableDisplay => {
  return {
    OrderId: data.OrderId,
    Employee: data.Employee,
    StatusType: mapOrderStatusToValue(data),
    EnrollmentType: mapOrderEnrollmentToValue(data),
    AmountTotal: data.AmountTotal,
    StartDateTime: data.StartDateTime,
  };
};

function hasFlag(value: number, flag: number) {
  return (value & flag) === flag;
}

export const mapOrderStatusToValue = (order: Order) => {
  if (hasFlag(order.StatusType, OrderStatusTypes.Voided)) return 'Voided';
  if (hasFlag(order.StatusType, OrderStatusTypes.Canceled)) return 'Cancelled';
  if (hasFlag(order.StatusType, OrderStatusTypes.Completed)) return 'Completed';
  if (hasFlag(order.StatusType, OrderStatusTypes.Open)) return 'Open';
  return 'Pending';
};

export const getOrderStatusSeverity = (order: Order) => {
  if (hasFlag(order.StatusType, OrderStatusTypes.Voided)) return 'warning';
  if (hasFlag(order.StatusType, OrderStatusTypes.Canceled)) return 'danger';
  if (hasFlag(order.StatusType, OrderStatusTypes.Completed)) return 'success';
  if (hasFlag(order.StatusType, OrderStatusTypes.Open)) return 'info';
  return 'info';
};

export const mapOrderEnrollmentToValue = (order: Order) => {
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.CanceledEnrollment)) return 'Cancelled';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramDeclined)) return 'Declined';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramExisting)) return 'Existing';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramEnrolled)) return 'Enrolled';
  return 'N/A';
};

export const getOrderEnrollmentSeverity = (order: Order) => {
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.CanceledEnrollment)) return 'danger';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramDeclined)) return 'warning';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramExisting)) return 'info';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramEnrolled)) return 'success';
  return 'warning';
};
