export enum OrderPaymentStatusTypes {
  Canceled = 1,
  CardPresented = 2,
  PaymentRequested = 4,
  PresentedEnrollment = 8,
  WaitingEnrollmentDecision = 16, // 0x00000010
  AcceptedEnrollment = 32, // 0x00000020
  DeclinedEnrollment = 64, // 0x00000040
  AlreadyMember = 128, // 0x00000080
  PresentedSelectOffer = 256, // 0x00000100
  CompletedSelectOffer = 512, // 0x00000200
  PresentedRecap = 1024, // 0x00000400
  RequestedAuthorization = 2048, // 0x00000800
  CompletedAuthorization = 4096, // 0x00001000
  Complete = 8192, // 0x00002000
  ApplyFailedToPOS = 16384, // 0x00004000
  TokenInPaymentDecision = 32768, // 0x00008000
  PaymentAdjustmentRequested = 65536, // 0x00010000
  ApplyingDiscount = 131072, // 0x00020000
  Batched = 262144, // 0x00040000
  VoidPending = 524288, // 0x00080000
  CancelPending = 1048576, // 0x00100000
  Voided = 2097152, // 0x00200000
  OpenTab = 4194304, // 0x00400000
  PosOutOfSync = 8388608, // 0x00800000
  ApplyingToPOS = 16777216, // 0x01000000
  AppliedToPOS = 33554432, // 0x02000000
}
