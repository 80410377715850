import { inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectOrders } from '@app/resources/ngrx/selectors';
import { OrderActions } from '@app/resources/ngrx/actions';

@Injectable()
export class TransactionModuleRouteResolver implements Resolve<any> {
  private readonly store = inject(Store);

  resolve(route: ActivatedRouteSnapshot) {
    return this.store.select(selectOrders).pipe(
      take(1),
      tap((orders) => {
        if (!orders?.length) this.store.dispatch(OrderActions.loadOrders());
      })
    );
  }
}
