export enum EnrollmentTypes {
  Opportunity = 1,
  LoyaltyProgramEnrolled = 2,
  LoyaltyProgramDeclined = 4,
  LoyaltyProgramExisting = 8,
  AssignedToLocation = 16, // 0x00000010
  AssignedToMerchant = 32, // 0x00000020
  MissedOpportunity = 64, // 0x00000040
  CanceledEnrollment = 128, // 0x00000080
  NoLongerMember = 256, // 0x00000100
  AssignedToCoalition = 512, // 0x00000200
}
