import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Order } from '@app/resources/services';

const sortOrderById = (a: Order, b: Order) => {
  return a.OrderId - b.OrderId;
};

export const orderAdapter: EntityAdapter<Order> = createEntityAdapter<Order>({
  sortComparer: sortOrderById,
  selectId: (e) => e?.OrderId ?? 0,
});

export const {
  selectIds: selectOrderIds,
  selectEntities: selectOrderEntities,
  selectAll: selectAllOrders,
  selectTotal: ordersCount,
} = orderAdapter.getSelectors();
