import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewsComponent } from './reviews.component';
import { ReviewsRoutingModule } from './reviews-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { OrderReviewModuleEffects } from '@app/areas/reviews/store';
import { OrderReviewsApiService } from '@app/areas/reviews/services';
import { ReviewModuleRouteResolver, ReviewRouteResolver } from '@app/areas/reviews/resolvers';

@NgModule({
  imports: [CommonModule, ReviewsRoutingModule, ReviewsComponent, EffectsModule.forFeature([OrderReviewModuleEffects])],
  providers: [OrderReviewsApiService, ReviewModuleRouteResolver, ReviewRouteResolver],
})
export class ReviewsModule {}
