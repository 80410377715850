import { createReducer, on } from '@ngrx/store';
import { QuestionState } from '@app/resources/ngrx/states';
import { QuestionActions } from '@app/resources/ngrx/actions';
import * as fromQuestionAdapter from '@app/resources/ngrx/adapters';

export const initialQuestionModuleState: QuestionState = fromQuestionAdapter.questionAdapter.getInitialState({
  selectedQuestionId: 0,
  selectId: 0,
  hasLoaded: false,
});

export const _questionReducer = createReducer(
  initialQuestionModuleState,
  on(QuestionActions.upsertQuestionSuccess, (state, { payload }) => {
    return {
      ...fromQuestionAdapter.questionAdapter.upsertOne(payload.question, state),
      hasLoaded: true,
    };
  }),
  on(QuestionActions.upsertManyQuestionSuccess, (state, { payload }) =>
    fromQuestionAdapter.questionAdapter.upsertMany(payload.questions, state)
  ),
  on(QuestionActions.loadQuestionsSuccess, (state, { payload }) => {
    state = fromQuestionAdapter.questionAdapter.removeAll({ ...state });
    return {
      ...fromQuestionAdapter.questionAdapter.addMany(payload.questions, state),
      hasLoaded: true,
    };
  }),
  on(QuestionActions.questionById, (state, { payload }) =>
    Object.assign({
      ...state,
      selectedQuestionId: payload.questionId,
    })
  )
);
