import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, take, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { isNotNullOrUndefined } from '@ep/shared';
import { Store } from '@ngrx/store';
import { selectCurrentOrder } from '@app/resources/ngrx/selectors';
import { OrderActions, RouterActions } from '@app/resources/ngrx/actions';
import { TransactionApiService } from '@app/areas/transactions/services';

@Injectable()
export class TransactionRouteResolver {
  private readonly transactionApiService = inject(TransactionApiService);
  private readonly store = inject(Store);

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    let orderId = parseInt(route.paramMap.get('orderId') ?? '');
    if (isNaN(orderId)) this.store.dispatch(RouterActions.navigateByUrl({ payload: { path: 'not-found' } }));
    this.store.dispatch(OrderActions.orderById({ payload: { orderId } }));

    return this.store.select(selectCurrentOrder).pipe(
      take(1),
      switchMap((order) => {
        if (order?.OrderId != orderId) {
          return this.transactionApiService.getTransaction(orderId).pipe(
            tap((order) => {
              if (!order) {
                this.store.dispatch(RouterActions.navigateByUrl({ payload: { path: 'not-found' } }));
              } else {
                this.store.dispatch(OrderActions.orderById({ payload: { orderId } }));
              }
            })
          );
        }
        this.store.dispatch(OrderActions.orderById({ payload: { orderId } }));
        return of(!!order);
      }),
      filter(isNotNullOrUndefined)
    );
  }
}
