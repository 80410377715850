import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { OrderActions, RouterActions } from '@app/resources/ngrx/actions';
import { TransactionApiService } from '@app/areas/transactions/services';
import { TransactionModuleActions } from '@app/areas/transactions/store/transaction-module.actions';

@Injectable()
export class TransactionModuleEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly transactionsApiService = inject(TransactionApiService);

  loadTransactions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderActions.loadOrders),
        mergeMap(() =>
          this.transactionsApiService.getTransactions().pipe(
            map((orders) => OrderActions.loadOrdersSuccess({ payload: { orders } })),
            catchError((error: { message: string }) =>
              of(OrderActions.loadOrdersError({ payload: { error: error.message } }))
            )
          )
        )
      ),
    { dispatch: true }
  );

  navigateToExistingTransactionForm = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TransactionModuleActions.navigateExistingTransaction),
        tap(({ payload }) => {
          this.store.dispatch(
            RouterActions.navigateByUrlWithLocation({
              payload: {
                path: `transactions/${payload.orderId}`,
              },
            })
          );
        })
      ),
    {
      dispatch: false,
    }
  );
}
