import { inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectConsumers } from '@app/resources/ngrx/selectors';
import { ConsumerActions } from '@app/resources/ngrx/actions';

@Injectable()
export class MemberModuleRouteResolver implements Resolve<any> {
  private readonly store = inject(Store);

  resolve(route: ActivatedRouteSnapshot) {
    return this.store.select(selectConsumers).pipe(
      take(1),
      tap((consumers) => {
        if (!consumers?.length) this.store.dispatch(ConsumerActions.loadConsumers());
      })
    );
  }
}
