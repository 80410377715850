import { Component } from '@angular/core';
import { TransactionsTableComponent } from '@app/areas/transactions/components';

@Component({
  template: '<app-transactions-table></app-transactions-table>',
  styleUrls: ['./transactions.component.scss'],
  imports: [TransactionsTableComponent],
  standalone: true,
})
export class TransactionsComponent {}
