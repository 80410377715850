import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, take, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { isNotNullOrUndefined } from '@ep/shared';
import { OrderReviewsApiService } from '../services';
import { Store } from '@ngrx/store';
import { selectCurrentOrderReview } from '@app/resources/ngrx/selectors';
import { OrderReviewActions } from '@app/resources/ngrx/actions';

@Injectable()
export class ReviewRouteResolver {
  private readonly orderReviewsApiService = inject(OrderReviewsApiService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    let orderReviewId = route.paramMap.get('orderReviewId') ?? '';
    this.store.dispatch(OrderReviewActions.orderReviewById({ payload: { orderReviewId } }));

    return this.store.select(selectCurrentOrderReview).pipe(
      take(1),
      switchMap((orderReview) => {
        if (orderReview?.OrderReviewId != orderReviewId) {
          return this.orderReviewsApiService.getReview(orderReviewId).pipe(
            tap((orderReview) => {
              if (!orderReview) {
                this.router.navigateByUrl('not-found');
              } else {
                this.store.dispatch(OrderReviewActions.orderReviewById({ payload: { orderReviewId } }));
              }
            })
          );
        }
        this.store.dispatch(OrderReviewActions.orderReviewById({ payload: { orderReviewId } }));
        return of(!!orderReview);
      }),
      filter(isNotNullOrUndefined)
    );
  }
}
