import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderReviewState } from '@app/resources/ngrx/states';
import { isNotNullOrUndefined } from '@ep/shared';
import { selectOrderReviewEntities } from '@app/resources/ngrx/adapters';

export const selectOrderReviewState = createFeatureSelector<OrderReviewState>('orderReview');
export const getOrderReviewEntities = createSelector(selectOrderReviewState, selectOrderReviewEntities);

export const getSelectedOrderReviewId = (state: OrderReviewState) => {
  return state.selectedOrderReviewId;
};
export const selectCurrentOrderReviewId = createSelector(selectOrderReviewState, getSelectedOrderReviewId);

export const hasReviewsLoaded = createSelector(selectOrderReviewState, (state) => state.hasLoaded);

export const selectOrderReviews = createSelector(
  getOrderReviewEntities,
  hasReviewsLoaded,
  (orderReviewEntities, hasLoaded) => {
    if (!hasLoaded) return null;
    return Object.values(orderReviewEntities).filter(isNotNullOrUndefined);
  }
);

export const selectOrderReviewsSortedByCreatedDate = createSelector(
  getOrderReviewEntities,
  hasReviewsLoaded,
  (orderReviewEntities, hasLoaded) => {
    if (!hasLoaded) return null;
    return Object.values(orderReviewEntities)
      .filter(isNotNullOrUndefined)
      .sort((a, b) => {
        const reviewCreatedDate1 = new Date(a.CreatedDateTime);
        const reviewCreatedDate2 = new Date(b.CreatedDateTime);
        return reviewCreatedDate2.getTime() - reviewCreatedDate1.getTime();
      });
  }
);
export const selectCurrentOrderReview = createSelector(
  getOrderReviewEntities,
  selectCurrentOrderReviewId,
  (orderReviewEntities, orderReviewId) => {
    return orderReviewEntities[orderReviewId ?? 0];
  }
);
