import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EpTableComponent, EPTableConfig, LoadingSpinnerDirective } from '@ep/shared';
import { Consumer } from '@app/resources/services';
import { Observable } from 'rxjs';
import { selectConsumers, selectConsumersSortedByName } from '@app/resources/ngrx/selectors';
import { IframeManagerService } from '@shared/services/iframe-manager.service';
import { MemberModuleActions } from '@app/areas/members/store';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-members-table',
  standalone: true,
  imports: [EpTableComponent, LoadingSpinnerDirective, AsyncPipe],
  templateUrl: './members-table.component.html',
  styleUrl: './members-table.component.scss',
})
export class MembersTableComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly iframeManagerService = inject(IframeManagerService);

  protected config: EPTableConfig<Consumer> = {
    header: 'members',
    pagination: {
      rows: 10,
      showCurrentPageReport: true,
    },
    columns: [
      {
        header: 'First Name',
        field: 'NameFirst',
        columnType: 'string',
        defaultValue: 'VALUED',
      },
      {
        header: 'Last Name',
        field: 'NameLast',
        columnType: 'string',
        defaultValue: 'CUSTOMER',
      },
      {
        header: 'Last Seen',
        field: 'LastSeenDate',
        columnType: 'date',
        defaultValue: null,
      },
    ],
    style: {},
    globalFilterFields: ['NameFirst', 'NameLast'],
    actions: {
      rowClick: (member: Consumer) => {
        this.store.dispatch(
          MemberModuleActions.navigateExistingMember({
            payload: { consumerId: member.ConsumerId },
          })
        );
      },
    },
  };

  protected members$: Observable<Consumer[] | null> = this.store.select(selectConsumersSortedByName);

  ngOnInit() {
    this.iframeManagerService.setShouldShowIframe(false);
  }
}
