import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderReview, OrderReviewService } from '@app/resources/services';
import { mapToOrderReviews } from '@app/areas/reviews/utils/mappers';

@Injectable()
export class OrderReviewsApiService {
  private readonly orderReviewService = inject(OrderReviewService);

  getReviews(): Observable<OrderReview[]> {
    return this.orderReviewService.findAll().pipe(map(mapToOrderReviews));
  }

  getReview(orderReviewId: string): Observable<boolean> {
    return this.orderReviewService.findUnique(orderReviewId).pipe(
      map((response) => !!response),
      catchError((err) => {
        console.error(err);
        return of(false);
      })
    );
  }
}
