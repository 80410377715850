import { OrderState } from '@app/resources/ngrx/states';
import { orderAdapter } from '@app/resources/ngrx/adapters';
import { createReducer, on } from '@ngrx/store';
import { OrderActions } from '@app/resources/ngrx/actions';
import * as fromOrderAdapter from '@app/resources/ngrx/adapters';

export const initialOrderModuleState: OrderState = orderAdapter.getInitialState({
  selectedOrderId: null,
  selectId: 0,
  hasLoaded: false,
});

export const _orderReducer = createReducer(
  initialOrderModuleState,
  on(OrderActions.loadOrdersSuccess, (state, { payload }) => {
    state = fromOrderAdapter.orderAdapter.removeAll({ ...state });
    return {
      ...fromOrderAdapter.orderAdapter.addMany(payload.orders, state),
      hasLoaded: true,
    };
  }),
  on(OrderActions.orderById, (state, { payload }) => {
    return Object.assign({
      ...state,
      selectedOrderId: payload.orderId,
    });
  })
);
