import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderState } from '@app/resources/ngrx/states';
import { isNotNullOrUndefined } from '@ep/shared';
import { selectOrderEntities } from '@app/resources/ngrx/adapters';

export const selectOrderState = createFeatureSelector<OrderState>('order');
export const getOrderEntities = createSelector(selectOrderState, selectOrderEntities);

export const hasOrdersLoaded = createSelector(selectOrderState, (state) => state.hasLoaded);

export const getSelectedOrderId = (state: OrderState) => {
  return state.selectedOrderId;
};
export const selectCurrentOrderId = createSelector(selectOrderState, getSelectedOrderId);

export const selectOrders = createSelector(getOrderEntities, hasOrdersLoaded, (orderEntities, hasLoaded) => {
  if (!hasLoaded) return null;
  return Object.values(orderEntities).filter(isNotNullOrUndefined);
});

export const selectOrdersSortedByStartDate = createSelector(
  getOrderEntities,
  hasOrdersLoaded,
  (orderEntities, hasLoaded) => {
    if (!hasLoaded) return null;
    return Object.values(orderEntities)
      .filter(isNotNullOrUndefined)
      .sort((a, b) => {
        const orderStartDate1 = new Date(a.StartDateTime);
        const orderStartDate2 = new Date(b.StartDateTime);
        return orderStartDate2.getTime() - orderStartDate1.getTime();
      });
  }
);

export const selectCurrentOrder = createSelector(getOrderEntities, selectCurrentOrderId, (orderEntities, orderId) => {
  return orderEntities[orderId ?? 0];
});
