import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrderReview } from '@app/resources/services';

enum OrderReviewActionTypes {
  LOAD_ORDER_REVIEWS = 'Load Order Reviews',
  LOAD_ORDER_REVIEWS_SUCCESS = 'Load Order Reviews Success',
  LOAD_ORDER_REVIEWS_ERROR = 'Load Order Reviews Error',
  SELECT_ORDER_REVIEW = 'Order Review By Id',
}

export const OrderReviewActions = createActionGroup({
  source: 'OrderReview',
  events: {
    [OrderReviewActionTypes.LOAD_ORDER_REVIEWS]: emptyProps(),
    [OrderReviewActionTypes.LOAD_ORDER_REVIEWS_SUCCESS]: props<{
      payload: { orderReviews: OrderReview[] };
    }>(),
    [OrderReviewActionTypes.LOAD_ORDER_REVIEWS_ERROR]: props<{ payload: { error: string } }>(),
    [OrderReviewActionTypes.SELECT_ORDER_REVIEW]: props<{ payload: { orderReviewId: string } }>(),
  },
});
