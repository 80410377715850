import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Invoice } from '@app/resources/services';

enum InvoiceActionTypes {
  LOAD_PAST_INVOICES = 'Load Past Invoices',
  LOAD_PAST_INVOICES_SUCCESS = 'Load Past Invoices Success',
  LOAD_PAST_INVOICES_ERROR = 'Load Past Invoices Error',
  UPSERT_PAST_INVOICE_SUCCESS = 'Upsert Past Invoice Success',
  SELECT_PAST_INVOICE = 'Past Invoice By Id',
}

export const InvoiceActions = createActionGroup({
  source: 'Invoice',
  events: {
    [InvoiceActionTypes.LOAD_PAST_INVOICES]: emptyProps(),
    [InvoiceActionTypes.LOAD_PAST_INVOICES_SUCCESS]: props<{ payload: { invoices: Invoice[] } }>(),
    [InvoiceActionTypes.LOAD_PAST_INVOICES_ERROR]: props<{ payload: { error: string } }>(),
    [InvoiceActionTypes.UPSERT_PAST_INVOICE_SUCCESS]: props<{ payload: { invoice: Invoice } }>(),
    [InvoiceActionTypes.SELECT_PAST_INVOICE]: props<{ payload: { invoiceId: number } }>(),
  },
});
