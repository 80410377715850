import { Component, inject, OnInit } from '@angular/core';
import { EpTableComponent, EPTableConfig, LoadingSpinnerDirective } from '@ep/shared';
import { Store } from '@ngrx/store';
import { IframeManagerService } from '@shared/services/iframe-manager.service';
import { Order } from '@app/resources/services';
import { Observable } from 'rxjs';
import { selectOrders, selectOrdersSortedByStartDate } from '@app/resources/ngrx/selectors';
import { TransactionModuleActions } from '@app/areas/transactions/store';
import { mapToTransactionTableDisplays } from '@app/areas/transactions/utils';
import { map } from 'rxjs/operators';
import { TransactionTableDisplay } from '@app/areas/transactions/models';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-transactions-table',
  standalone: true,
  imports: [EpTableComponent, AsyncPipe, LoadingSpinnerDirective],
  templateUrl: './transactions-table.component.html',
  styleUrl: './transactions-table.component.scss',
})
export class TransactionsTableComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly iframeManagerService = inject(IframeManagerService);

  protected config: EPTableConfig<TransactionTableDisplay> = {
    header: 'transactions',
    pagination: {
      rows: 10,
      showCurrentPageReport: true,
    },
    columns: [
      {
        header: 'Date',
        field: 'StartDateTime',
        columnType: 'date',
        dateFormat: 'MM-dd-YYYY',
        defaultValue: null,
      },
      {
        header: 'Employee',
        field: 'Employee',
        columnType: 'string',
        defaultValue: 'Missing employee',
      },
      {
        header: 'Status',
        field: 'StatusType',
        columnType: 'string',
        defaultValue: 'Open',
      },
      {
        header: 'Enrollment',
        field: 'EnrollmentType',
        columnType: 'string',
        defaultValue: 'Declined',
      },
      {
        header: 'Total',
        field: 'AmountTotal',
        columnType: 'price',
        defaultValue: 0,
      },
    ],
    style: {},
    globalFilterFields: ['Employee', 'NameLast'],
    actions: {
      rowClick: (transaction: Order) => {
        this.store.dispatch(
          TransactionModuleActions.navigateExistingTransaction({
            payload: { orderId: transaction.OrderId },
          })
        );
      },
    },
  };

  protected transactions$: Observable<TransactionTableDisplay[] | null> = this.store
    .select(selectOrdersSortedByStartDate)
    .pipe(map(mapToTransactionTableDisplays));

  ngOnInit() {
    this.iframeManagerService.setShouldShowIframe(false);
  }
}
