import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  ConfigurationService,
  isNotNullOrUndefined,
  LoadingSpinnerDirective,
  ViewCardComponent,
  ViewCardConfig,
} from '@ep/shared';
import { EmployeeAccessTypes } from '@shared/enums';
import { IframeManagerService } from '@shared/services/iframe-manager.service';
import { Observable, take, tap } from 'rxjs';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { RouterActions } from '@app/resources/ngrx/actions';
import { selectApplicationProfile } from '@app/resources/ngrx/selectors';
import { filter, map } from 'rxjs/operators';
import { CARD_CONFIGS } from '@app/areas/admin/utils/constants';
import { selectJwt } from '@app/resources/ngrx/selectors/credentials.selector';
import { CredentialsService } from '@shared/services/credentials.service';

export interface AdminCardConfig {
  config: ViewCardConfig;
  accessType: EmployeeAccessTypes;
  path: string;
}

@UntilDestroy()
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  standalone: true,
  imports: [NgForOf, NgIf, LoadingSpinnerDirective, ViewCardComponent, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminComponent {
  private readonly credentialsService = inject(CredentialsService);
  private readonly configurationService = inject(ConfigurationService);
  private readonly iframeManagerService = inject(IframeManagerService);
  private readonly store = inject(Store);

  isLoading: boolean = false;

  cardConfigurations = CARD_CONFIGS;

  private readonly jwt$ = this.store.select(selectJwt);

  protected readonly hasPermission$: { [index: string]: Observable<boolean> } = {
    invoices: this.jwt$.pipe(map((jwt) => this.credentialsService.hasAccess(jwt, EmployeeAccessTypes.BillingAdmin))),
    devices: this.jwt$.pipe(map((jwt) => this.credentialsService.hasAccess(jwt, EmployeeAccessTypes.DeviceAdmin))),
    employees: this.jwt$.pipe(map((jwt) => this.credentialsService.hasAccess(jwt, EmployeeAccessTypes.EmployeeAdmin))),
    locations: this.jwt$.pipe(map((jwt) => this.credentialsService.hasAccess(jwt, EmployeeAccessTypes.LocationAdmin))),
    ['loyalty-program']: this.jwt$.pipe(
      map((jwt) => this.credentialsService.hasAccess(jwt, EmployeeAccessTypes.LoyaltyProgramAdmin))
    ),
    questions: this.jwt$.pipe(map((jwt) => this.credentialsService.hasAccess(jwt, EmployeeAccessTypes.QuestionAdmin))),
  };

  protected trackByHeader(index: number, item: AdminCardConfig) {
    return item.config.header;
  }

  navigateToPath(path: string) {
    this.store
      .select(selectApplicationProfile)
      .pipe(
        take(1),
        filter(isNotNullOrUndefined),
        tap((applicationProfile) => {
          if (
            !path.includes('locations') &&
            !path.includes('devices') &&
            !path.includes('loyalty-program') &&
            !path.includes('questions') &&
            !path.includes('employees') &&
            !path.includes('invoices')
          ) {
            const iframe = this.iframeManagerService.generateIframeSrc(
              this.configurationService.merchantPortalUrlLegacy ?? environment.merchantPortalUrlLegacy,
              applicationProfile.Location.LocationId,
              path.toLowerCase()
            );
            this.iframeManagerService.setIframeSrc(iframe);
          }
          this.store.dispatch(RouterActions.navigateByUrlWithLocation({ payload: { path: `admin/${path}` } }));
        })
      )
      .subscribe();
  }
}
