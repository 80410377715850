import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { OrderReview } from '@app/resources/services';

const sortOrderReviewById = (a: OrderReview, b: OrderReview) => {
  return a.OrderReviewId.localeCompare(b.OrderReviewId);
};

export const orderReviewAdapter: EntityAdapter<OrderReview> = createEntityAdapter<OrderReview>({
  sortComparer: sortOrderReviewById,
  selectId: (e) => e?.OrderReviewId ?? 0,
});

export const {
  selectIds: selectOrderReviewIds,
  selectEntities: selectOrderReviewEntities,
  selectAll: selectAllOrderReviews,
  selectTotal: orderReviewsCount,
} = orderReviewAdapter.getSelectors();
