import { OrderReviewState } from '@app/resources/ngrx/states';
import { orderReviewAdapter } from '@app/resources/ngrx/adapters';
import { createReducer, on } from '@ngrx/store';
import { OrderReviewActions } from '@app/resources/ngrx/actions';
import * as fromOrderReviewAdapter from '@app/resources/ngrx/adapters';

export const initialOrderReviewModuleState: OrderReviewState = orderReviewAdapter.getInitialState({
  selectedOrderReviewId: null,
  selectId: 0,
  hasLoaded: false,
});

export const _orderReviewReducer = createReducer(
  initialOrderReviewModuleState,
  on(OrderReviewActions.loadOrderReviewsSuccess, (state, { payload }) => {
    state = fromOrderReviewAdapter.orderReviewAdapter.removeAll({ ...state });
    return {
      ...fromOrderReviewAdapter.orderReviewAdapter.addMany(payload.orderReviews, state),
      hasLoaded: true,
    };
  }),
  on(OrderReviewActions.orderReviewById, (state, { payload }) => {
    return Object.assign({
      ...state,
      selectedOrderReviewId: payload.orderReviewId,
    });
  })
);
