import { OrderReview, OrderReviewResponse, OrderReviewsResponse } from '@app/resources/services';
import { mapToDeviceTracking, parseDeviceSettingsJson } from '@app/areas/admin/submodules/devices/utils';
import { isNotNullOrUndefined } from '@shared/utils';

const MAX_REVIEW_THUMBS_UP_QUESTION_RATING = 5;

export const mapOrderReviewStatusToValue = (orderReview: OrderReview) => {
  return !isNotNullOrUndefined(orderReview.Score)
    ? 'Not Answered'
    : orderReview.Score === MAX_REVIEW_THUMBS_UP_QUESTION_RATING
    ? 'Great'
    : 'Poor';
};

export const getOrderReviewStatusSeverity = (orderReview: OrderReview) => {
  return !isNotNullOrUndefined(orderReview.Score)
    ? 'warning'
    : orderReview.Score === MAX_REVIEW_THUMBS_UP_QUESTION_RATING
    ? 'success'
    : 'danger';
};

export const mapToOrderReviews = (data: OrderReviewsResponse): OrderReview[] => {
  return data.$values.map(mapToOrderReview);
};

export const mapToOrderReview = (data: OrderReviewResponse): OrderReview => {
  return {
    OrderReviewId: data.orderReviewId,
    Consumer: data.consumer,
    Score: data.score,
    CreatedDateTime: data.createdDateTime,
    UpdateDateTime: data.updateDateTime,
  };
};
