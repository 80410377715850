import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Consumer, ConsumerService } from '@app/resources/services';
import { mapToConsumers } from '@app/areas/members/utils';

@Injectable()
export class MembersApiService {
  private readonly consumerService = inject(ConsumerService);

  getMembers(): Observable<Consumer[]> {
    return this.consumerService.findAll().pipe(map(mapToConsumers));
  }

  getMember(consumerId: number): Observable<boolean> {
    return this.consumerService.findUnique(consumerId).pipe(
      map((response) => !!response),
      catchError((err) => {
        console.error(err);
        return of(false);
      })
    );
  }
}
