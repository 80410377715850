import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Consumer } from '@app/resources/services';

const sortConsumerById = (a: Consumer, b: Consumer) => {
  return a.ConsumerId - b.ConsumerId;
};

export const consumerAdapter: EntityAdapter<Consumer> = createEntityAdapter<Consumer>({
  sortComparer: sortConsumerById,
  selectId: (e) => e?.ConsumerId ?? 0,
});

export const {
  selectIds: selectConsumerIds,
  selectEntities: selectConsumerEntities,
  selectAll: selectAllConsumers,
  selectTotal: consumersCount,
} = consumerAdapter.getSelectors();
