import { createActionGroup, props } from '@ngrx/store';

/*
 * Transaction Module Actions
 */
enum TransactionModuleActionTypes {
  NAVIGATE_EXISTING_TRANSACTION = 'Navigate Existing Transaction',
}

export const TransactionModuleActions = createActionGroup({
  source: 'Transaction Module',
  events: {
    [TransactionModuleActionTypes.NAVIGATE_EXISTING_TRANSACTION]: props<{ payload: { orderId: number } }>(),
  },
});
