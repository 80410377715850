import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MembersTableComponent } from '@app/areas/members/components';

@UntilDestroy()
@Component({
  template: '<app-members-table></app-members-table>',
  styleUrls: ['./members.component.scss'],
  standalone: true,
  imports: [MembersTableComponent],
})
export class MembersComponent {}
