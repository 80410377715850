import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionsComponent } from './transactions.component';
import { TransactionsRoutingModule } from './transactions-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { TransactionModuleEffects } from '@app/areas/transactions/store';
import { TransactionApiService } from '@app/areas/transactions/services';
import { TransactionModuleRouteResolver, TransactionRouteResolver } from '@app/areas/transactions/resolvers';

@NgModule({
  imports: [
    CommonModule,
    TransactionsRoutingModule,
    TransactionsComponent,
    EffectsModule.forFeature([TransactionModuleEffects]),
  ],
  providers: [TransactionApiService, TransactionModuleRouteResolver, TransactionRouteResolver],
})
export class TransactionsModule {}
