import { Component, inject } from '@angular/core';
import { IframeManagerService } from '@shared/services/iframe-manager.service';
import { ApplicationProfileService } from '@shared/services/application-profile.service';
import { ConfigurationService } from '@ep/shared';
import { environment } from '@env/environment';
import { isNotNullOrUndefined } from '@shared/utils';
import { Store } from '@ngrx/store';
import { selectCurrentConsumerId } from '@app/resources/ngrx/selectors';
import { take, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-existing-member',
  standalone: true,
  imports: [],
  templateUrl: './existing-member.component.html',
  styleUrl: './existing-member.component.scss',
})
export class ExistingMemberComponent {
  private readonly iframeManagerService = inject(IframeManagerService);
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly configurationService = inject(ConfigurationService);
  private readonly store = inject(Store);
  constructor() {
    this.store
      .select(selectCurrentConsumerId)
      .pipe(
        filter(isNotNullOrUndefined),
        take(1),
        tap((consumerId) => {
          const iframeSrc = this.iframeManagerService.generateIframeSrc(
            this.configurationService.merchantPortalUrlLegacy ?? environment.merchantPortalUrlLegacy,
            this.applicationProfileService.getApplicationProfile().Location.LocationId,
            'members',
            `${consumerId}`
          );
          if (iframeSrc) {
            this.iframeManagerService.setIframeSrc(iframeSrc);
            this.iframeManagerService.setShouldShowIframe(true);
          }
        })
      )
      .subscribe();
  }
}
