import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferApiService } from '@app/areas/offers/services/offer.api.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApplicationProfileService } from '@shared/services/application-profile.service';
import { EpTableComponent, EPTableConfig } from '@ep/shared';
import { Offer } from '@app/resources/services';
import { getOfferStatusSeverity, mapOfferStatusToValue, sortByStatusThenStartDate } from '@app/areas/offers/utils';
import { OfferStatusTypes } from '@shared/enums';
import { TimezoneService } from '@app/resources/services/timezone.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-offer-table',
  templateUrl: './offer-table.component.html',
  styleUrls: ['./offer-table.component.scss'],
  standalone: true,
  imports: [AsyncPipe, EpTableComponent, NgIf],
})
export class OfferTableComponent {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly offerApiService = inject(OfferApiService);
  private readonly timezoneService = inject(TimezoneService);
  private readonly applicationProfileService = inject(ApplicationProfileService);

  protected config: EPTableConfig<Offer> = {
    header: 'offers',
    pagination: {
      rows: 10,
      showCurrentPageReport: true,
    },
    columns: [
      {
        header: 'Name',
        field: 'namePrivate',
        columnType: 'string',
        defaultValue: 'Missing Name',
      },
      {
        header: 'Start Date',
        field: 'startDateTime',
        columnType: 'string',
        defaultValue: 'Ongoing',
      },
      {
        header: 'Expiration Date',
        field: 'endDateTime',
        columnType: 'string',
        defaultValue: 'Ongoing',
      },
      {
        header: 'Text Schedule',
        field: 'sendTime',
        columnType: 'string',
        defaultValue: 'No text sent',
      },
      {
        header: 'Status',
        field: 'statusType',
        columnType: 'categorical',
        defaultValue: 'N/A',
        fieldToValueMap: mapOfferStatusToValue,
        severity: getOfferStatusSeverity,
      },
    ],
    style: {
      'min-width': '50rem',
      border: '5px',
    },
    globalFilterFields: ['name'],
    actions: {
      addNew: () => {
        this.router.navigate(['new'], { relativeTo: this.route });
      },
      rowClick: (offer: Offer) => {
        this.router.navigate([`${offer.offerId}`], { relativeTo: this.route });
      },
    },
  };

  protected offers$: Observable<Offer[]> = this.applicationProfileService.applicationProfile$.pipe(
    switchMap((appProfile) => {
      const location = appProfile.Location;
      return this.offerApiService.getOffers(location.LocationId);
    }),
    map((offers: Offer[] | null) => {
      if (offers) {
        return [
          ...offers.filter((o) => o.statusType === OfferStatusTypes.Active).sort(sortByStatusThenStartDate),
          ...offers
            .filter((o) => o.statusType === OfferStatusTypes.Pending || o.statusType === OfferStatusTypes.PendingNoText)
            .sort(sortByStatusThenStartDate),
          ...offers
            .filter(
              (o) =>
                o.statusType !== OfferStatusTypes.Active &&
                o.statusType !== OfferStatusTypes.Pending &&
                o.statusType !== OfferStatusTypes.PendingNoText
            )
            .sort(sortByStatusThenStartDate),
        ].map((offer) => {
          return {
            ...offer,
            startDateTime: this.timezoneService
              .moment(offer.startDateTime)
              .add(this.timezoneService.getClientOffset(), 'hours')
              .subtract(this.timezoneService.getDSTTime(offer.startDateTime), 'hours')
              .format('MM/DD/YYYY, h:mm a'),
            endDateTime: this.timezoneService
              .moment(offer.endDateTime)
              .add(this.timezoneService.getClientOffset(), 'hours')
              .subtract(this.timezoneService.getDSTTime(offer.startDateTime), 'hours')
              .format('MM/DD/YYYY, h:mm a'),
            sendTime: offer.sendTime
              ? this.timezoneService
                  .moment(offer.sendTime)
                  .subtract(this.timezoneService.getDSTTime(offer.sendTime), 'hours')
                  .format('MM/DD/YYYY, h:mm a')
              : null,
          };
        });
      }
      return [];
    })
  );

  getDSTTime(date: moment.MomentInput) {
    return this.timezoneService.isDST(date) ? 0 : 1;
  }
}
