import { Consumer, ConsumerResponse, ConsumersResponse } from '@app/resources/services';

export const mapToConsumers = (data: ConsumersResponse): Consumer[] => {
  return data.$values.map(mapToConsumer);
};

export const mapToConsumer = (data: ConsumerResponse): Consumer => {
  return {
    ConsumerId: data.consumerId,
    NameFirst: data.nameFirst,
    NameLast: data.nameLast,
    LastSeenDate: data.lastSeenDate,
  };
};
