import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';

export interface ConsumerResponse {
  consumerId: number;
  nameFirst: string;
  nameLast: string;
  lastSeenDate: Date | string;
}

export interface ConsumersResponse {
  $values: ConsumerResponse[];
}

export interface Consumer {
  ConsumerId: number;
  NameFirst: string;
  NameLast: string;
  LastSeenDate: Date | string;
}

export interface LegacyConsumer {
  ConsumerId: number;
}

export interface LegacyConsumerResponse {
  Consumer: LegacyConsumer;
}

@Injectable({
  providedIn: 'root',
})
export class ConsumerService extends BaseApiService {
  private readonly consumerEndpoint = '/v2/Consumers';
  private readonly consumerLegacyEndpoint = '/Consumer';

  findAll() {
    return this.get<ConsumersResponse>(`${this.consumerEndpoint}`).pipe(
      catchError((err) => {
        console.error(err);
        const emptyConsumersResponse: ConsumersResponse = { $values: [] };
        return of(emptyConsumersResponse);
      })
    );
  }

  findUnique(consumerId: number) {
    return this.get<LegacyConsumerResponse>(`${this.consumerLegacyEndpoint}/${consumerId}`, {}, true);
  }
}
