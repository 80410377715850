import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrderReviewsTableComponent } from '@app/areas/reviews/components';

@UntilDestroy()
@Component({
  template: '<app-order-reviews-table></app-order-reviews-table>',
  styleUrls: ['./reviews.component.scss'],
  standalone: true,
  imports: [OrderReviewsTableComponent],
})
export class ReviewsComponent {}
