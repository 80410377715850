import { createActionGroup, props } from '@ngrx/store';

/*
 * Member Module Actions
 */
enum MemberModuleActionTypes {
  NAVIGATE_EXISTING_MEMBER = 'Navigate Existing Member',
}

export const MemberModuleActions = createActionGroup({
  source: 'Member Module',
  events: {
    [MemberModuleActionTypes.NAVIGATE_EXISTING_MEMBER]: props<{ payload: { consumerId: number } }>(),
  },
});
