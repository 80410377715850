import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { OrderReviewsApiService } from '@app/areas/reviews/services';
import { OrderReviewActions, RouterActions } from '@app/resources/ngrx/actions';
import { OrderReviewModuleActions } from '@app/areas/reviews/store/order-review-module.action';

@Injectable()
export class OrderReviewModuleEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly orderReviewsApiService = inject(OrderReviewsApiService);

  loadOrderReviews = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderReviewActions.loadOrderReviews),
        mergeMap(() =>
          this.orderReviewsApiService.getReviews().pipe(
            map((orderReviews) => OrderReviewActions.loadOrderReviewsSuccess({ payload: { orderReviews } })),
            catchError((error: { message: string }) =>
              of(OrderReviewActions.loadOrderReviewsError({ payload: { error: error.message } }))
            )
          )
        )
      ),
    { dispatch: true }
  );

  navigateToExistingOrderReviewForm = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderReviewModuleActions.navigateExistingOrderReview),
        tap(({ payload }) => {
          this.store.dispatch(
            RouterActions.navigateByUrlWithLocation({
              payload: {
                path: `reviews/${payload.orderReviewId}`,
              },
            })
          );
        })
      ),
    {
      dispatch: false,
    }
  );
}
