import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouteReusableStrategy, ApiPrefixInterceptor, SharedModule } from '@shared';
import { AuthModule } from '@app/auth';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AdminModule } from './areas/admin/admin.module';
import { DashboardModule } from './areas/dashboard/dashboard.module';
import { MembersModule } from './areas/members/members.module';
import { OffersModule } from './areas/offers/offers.module';
import { ChangePasswordModule } from './areas/change-password/change-password.module';
import { ToastModule } from 'primeng/toast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessagesModule } from './areas/messages/messages.module';
import { ProductsModule } from './areas/products/products.module';
import { ReportsModule } from './areas/reports/reports.module';
import { ReviewsModule } from './areas/reviews/reviews.module';
import { TransactionsModule } from './areas/transactions/transactions.module';
import { NotFoundModule } from './areas/not-found/not-found.module';
import { EnrollModule } from './enroll/enroll.module';
import '@shared/extensions/date.extensions';
import assetCacheBusting from '../../asset-cache-busting.json';
import { SignalRService } from '@app/resources/services/signalr.service';
import { getEnvironment } from '@shared/utils';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from '@app/resources/ngrx/reducers';
import { effects } from '@app/resources/ngrx/effects';
import { config } from '@app/resources/ngrx/dev-tools/store-devtools.config';
import { CustomSerializer } from '@app/resources/ngrx/states/router.state';
import { ConfigurationService } from '@ep/shared';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: false }),
    FormsModule,
    RouterModule,
    TranslateModule.forRoot(),
    NgbModule,
    SharedModule,
    ShellModule,
    AuthModule,
    AdminModule,
    DashboardModule,
    MembersModule,
    OffersModule,
    MessagesModule,
    ProductsModule,
    ReportsModule,
    ReviewsModule,
    TransactionsModule,
    ChangePasswordModule,
    NotFoundModule,
    EnrollModule,
    ToastModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument(config),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configurationService: ConfigurationService) => () =>
        configurationService.load(
          getEnvironment(),
          assetCacheBusting[getEnvironment() as keyof typeof assetCacheBusting]
        ),
      deps: [ConfigurationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    SignalRService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(configurationService: ConfigurationService, private swUpdate: SwUpdate) {
    if (configurationService.isProduction) {
      this.swUpdate.activateUpdate().then(() => {});
    }
  }
}
