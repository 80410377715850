import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { TransactionsComponent } from './transactions.component';
import { AccessGuard } from '@app/@shared/guards/access.guard';
import { AccessType } from '@app/@shared/enums/access-types.enum';
import { TransactionModuleRouteResolver, TransactionRouteResolver } from '@app/areas/transactions/resolvers';
import { ExistingTransactionComponent } from '@app/areas/transactions/components';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':locationId/transactions',
      component: TransactionsComponent,
      canActivate: [AccessGuard],
      data: { title: 'Transactions', requiredAccessTypes: [AccessType.OrderAdmin] },
      resolve: { message: TransactionModuleRouteResolver },
    },
    {
      path: ':locationId/transactions/:orderId',
      component: ExistingTransactionComponent,
      canActivate: [AccessGuard],
      data: { title: 'Transactions', requiredAccessTypes: [AccessType.OrderAdmin] },
      resolve: { data: TransactionModuleRouteResolver, message: TransactionRouteResolver },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionsRoutingModule {}
