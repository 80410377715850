import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Consumer } from '@app/resources/services';

enum ConsumerActionTypes {
  LOAD_CONSUMERS = 'Load Consumers',
  LOAD_CONSUMERS_SUCCESS = 'Load Consumers Success',
  LOAD_CONSUMERS_ERROR = 'Load Consumers Error',
  SELECT_CONSUMER = 'Consumer By Id',
}

export const ConsumerActions = createActionGroup({
  source: 'Consumer',
  events: {
    [ConsumerActionTypes.LOAD_CONSUMERS]: emptyProps(),
    [ConsumerActionTypes.LOAD_CONSUMERS_SUCCESS]: props<{
      payload: { consumers: Consumer[] };
    }>(),
    [ConsumerActionTypes.LOAD_CONSUMERS_ERROR]: props<{ payload: { error: string } }>(),
    [ConsumerActionTypes.SELECT_CONSUMER]: props<{ payload: { consumerId: number } }>(),
  },
});
